import React from "react";
import "./css/about.css";
function about() {
	<meta name="viewport" content="width=device-width, initial-scale=1" />;
	return (
		<div>
			<head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</head>
			<div className="about-bg-color">
				<h1 className="about-header">About Simra Khan</h1>
				<div className="about-box">
					<p className="about-body">
						On August 6th, 1999, the world was blessed with the birth of the one
						and only Simra Khan (the only one that really matters. No offense to
						any other Simra Khan's reading this, but the one im talking about is
						the best hands down). Simra is an absolutely amazing woman with
						countless shining qualities. She is the kindest, most caring, most
						supportive, most empathetic and most fun person that you will ever
						meet! She has an incredible personality that brings out the best in
						people she is around. On top of that, she is breathtakingly
						gorgeous! She will argue that she isn't to her last breath but in
						reality she is one of the most beautiful women that has ever walked
						this earth.
					</p>
					<p className="about-body">
						Simra is surrounded by friends and family both whom she loves and
						adores and vice versa. She would go to the ends of the earth for
						them. Shes constantly talks about her sisters Yusra and Iqra about
						how beautiful and how she loves spending time with them even though
						she can get annoyed sometimes :P She absolutely loves all her
						cousins like Nahiba, Juveria, Areeba, and Ayesha and talks about
						them any chance she gets. Cant forget her friends! Simra has a tight
						group who she always enjoys being around. Her friends Kabir, Zain,
						Aidah, and the legendary Kiffs all seem like great people and I'm
						glad she has each and every one of them. Simra has and always will
						be a joy to be around and you are lucky if you have the chance to
						get to know her!
					</p>
					<p className="about-body">
						Simra is the most special person ive ever had the honor of meeting
						and getting to know. She enchances the lives of everyone she is
						around and lights up any room she enters. Shes deserves the very
						best in the world always and forever!!!
					</p>
				</div>
			</div>
		</div>
	);
}

export default about;
