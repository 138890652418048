import React from "react";
import "./css/songs.css";
function songs() {
	return (
		<div className="songs-bg-color">
			<h1 className="songs-favorites">All your favorite songs in one place!</h1>
			<div className="songs-box">
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/djKdPZiJdvA?si=oZnNQC3ny0ZGivUS"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/17KP0nEIT3w?si=kHx7X_hBRiy6XlCr"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/d5gWx_hkI8U?si=1oV3GBYljiPDzFJR"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/O1ZDZvl9cYk?si=o89aaOn-mmGiIIEI"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/eTrlGv_ok70?si=8Ex3PEiHStQkKlpe"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/ScByWBRyux0?si=K_YOY9mvkFyOHLui"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/CTciuhTsZe4?si=rT9MK6vms4WN9g9A"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
			</div>
			<h1 className="songs-favorites">Songs that you'll like </h1>
			<div className="songs-box">
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/NDHY1D0tKRA?si=Hy_ciTJ_R6joFcVV"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/fRtU-2jgnHU?si=2i6G2xD4jrXrqYcD"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/y2xyq6vsL08?si=biCu9i-07xESZrSH"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/be3hbNjjDaY?si=wqjXnADFSrDF8hp2"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/zeuTEYL3L3I?si=fsrnnxHNN-qaN9aR"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/HZTOqzdMQMU?si=jJl7iL0F_jNfJTTq"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/nbD4q9wXr9Y?si=q-LVQgTikGbcp81c"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerpolicy="strict-origin-when-cross-origin"
					allowFullScreen="true"
				></iframe>
			</div>
		</div>
	);
}

export default songs;
