import "./css/testimonials.css";

function Testimonials() {
	return (
		<div className="testimonial-bg-color">
			<ol className="Test">
				<li>
					"She is the most amazing, most beautiful, and most special person ive
					ever had the pleasure of meeting" <p> -Jacob</p>
				</li>
				<li>
					"Gurlllll she absolutely slays at everything she does and im totes
					jealous of her. SLAYYY QUEEN" <p> -Jennie</p>
				</li>
				<li>
					"Shes looks like a snack"<p> -Kiffs</p>
				</li>
				<li>
					"Simra is gorgeous, wise, funny, and caring. I'm the love of her
					life."
					<p> -Kiffs</p>
				</li>
				<li>
					"She's the best sister ever despite her constant screaming and
					gasping" <p> -Iqra (Probably)</p>
				</li>
				<li>
					"Simra is kindest, most beautiful, yet short tempered person ive ever
					met" <p> -Iqra</p>
				</li>
				<li>
					"Simra is helpful, pretty, smart and sooooo amazing. Shes also
					annoying" <p> -Yusra</p>
				</li>
				<li>
					"Shes the bestest, nicest human alive. Shes perfect but also sometime
					grumpy" <p> -Kabir</p>
				</li>
				<li>
					"Simra is Positive, Trustworthy, Supportive, and Elegant"
					<p> -Zain</p>
				</li>
				<li>
					"Aapi is my favorite cousin ever" <p> -Juvenille</p>
				</li>
				<li>
					"Aapi is so smart and supportive. Shes a natural charmer and is always
					understanding no matter what." <p> -Juvenille</p>
				</li>
				<li>
					"Simra is so cute and kind. I can trust her with all my secrets and I
					know she will keep them always." <p> -Nahida</p>
				</li>
				<li>
					"She's the nicest person in the world and she's my confidante. I can
					trust her with literally anything" <p> -Alisha</p>
				</li>
				<li>
					"Despite her disgusting choices she makes, she is a good person. She
					is also very boujee" <p> -Iliad</p>
				</li>
				<li>
					Simra is one of my biggest fans and is the best ever!{" "}
					<p>-Jungkook (Probably what he would say)</p>
				</li>
				<li>
					"Simra 1080 Windmill dunked a basketball into my net. It was insane"
					<p> -Basketball Goal</p>
				</li>
				<li>
					"Im my babys good boy" <p> -2nd Best Good Boy</p>
				</li>
				<li>
					"Goo Goo Gaa Gaa" <p> -Other Nephew</p>
				</li>
			</ol>
		</div>
	);
}

export default Testimonials;
