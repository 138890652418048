import React from "react";
import "./css/wrestling.css";
import roman from "./images/Roman_Reigns_Birthday.jpg";
import john from "./images/John Cena Birthday.jpg";
import video from "./images/Cena_Video.mp4";

function Wrestling() {
	return (
		<div className="Wrestling">
			<img className="roman" src={roman} alt="Roman Reigns Birthday" />
			<img className="john" src={john} alt="John Cena Birthday" />
			<video className="video" src={video} width="800" height="400" controls />
		</div>
	);
}

export default Wrestling;
