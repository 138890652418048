import "./css/home.css";

function Home() {
	return (
		<div className="home-bg-color">
			<div id="heart"></div>
			<h1 className="home-korean">
				Simra 생일 축하해요 ! 최고의 생일을 보내길 바랍니다. 당신은 정말 놀랍고
				최고의 대우를 받을 자격이 있습니다!
			</h1>
		</div>
	);
}

export default Home;
