import "./css/navigation.css";
import { Outlet, Link } from "react-router-dom";

function Navigation() {
	return (
		<>
			<nav>
				<ul className="NavList">
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<Link to="/testimonials">Testimonials</Link>
					</li>
					<li>
						<Link to="/wrestling"> Wrestling</Link>
					</li>
					<li>
						<Link to="/songs">Songs</Link>
					</li>
				</ul>
			</nav>

			<Outlet />
		</>
	);
}

export default Navigation;
