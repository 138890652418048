import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./header";
import Navigation from "./Navigation";
import Home from "./Home";
import About from "./about";
import Testimonials from "./testimonials";
import Wrestling from "./Wrestling";
import Songs from "./songs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Header />
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<>
							<Navigation />
							<Home />
						</>
					}
				/>
				<Route
					path="/about"
					element={
						<>
							<Navigation />
							<About />
						</>
					}
				/>
				<Route
					path="/testimonials"
					element={
						<>
							<Navigation />
							<Testimonials />
						</>
					}
				/>
				<Route
					path="/wrestling"
					element={
						<>
							<Navigation />
							<Wrestling />
						</>
					}
				/>
				<Route
					path="/songs"
					element={
						<>
							<Navigation />
							<Songs />
						</>
					}
				/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
