import "./css/header.css";

function Header() {
	return (
		<div className="Header">
			<p>HAPPY BIRTHDAY SIMRA!!!!!</p>
		</div>
	);
}
export default Header;
